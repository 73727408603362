import { get, post } from '@/api/index'
const pre = '/bapi/v1/fellow/'

/**
 * 获取员工名片信息
*/
export const getEmpCard = params => {
  return get(pre + 'getById', params)
}

/**
 * 获取员工名片信息
*/
export const getFlowList = params => {
  return post(pre + 'list', params)
}
