import { callPhone, postMessageReceiveInfo, postMessageSendInfo } from '@/utils/utils'

export default {
  data() {
    return {
      data: {
        // name: '',
        // position: '',
        // avatar: '',
        // qrCode: '',
        // // qrCode: '/iframe-image/54c001b2-5f27-43e3-9e4f-a6898a3f80751601017469857%E7%BC%96%E7%BB%84%208.png',
        // mobile: '',
        // hotPhone: '',
        // dealerShortName: '',
        // address: '',
        // latitude: 31.256668,
        // longitude: 121.272963
      },
      // isShow:false,
      // 名片可展示数据配置
      // template: {
      //   mobile: {
      //     name: '手机号',
      //     icon: 'iconnewMobilePhone',
      //     click: this.openCallPhone,
      //     span: '12',
      //   },
      //   hotPhone: {
      //     name: '座机',
      //     icon: 'iconnewPhone',
      //     click: this.openCallPhone,
      //     span: '12',
      //   },
      //   dealerShortName: {
      //     name: '公司',
      //     icon: 'iconnewHome2x',
      //     span: '24',
      //   },
      //   address: {
      //     name: '地图',
      //     icon: 'iconnewAddress',
      //     click: this.openMap,
      //     span: '24',
      //   },
      // },
      // icon: {
      //   share,
      //   WeChat,
      //   info,
      //   avatarBg,
      //   logo,
      //   phone,
      //   dealer,
      //   local,
      //   QRCode
      // },
      // 该名片模板展示数据，顺序展示
      // sort: ['mobile', 'hotPhone', 'dealerShortName', 'address'],

      // 按钮样式
      uploadButtonStyle: {
        borderColor: '#4F77AA',
        backgroundColor: '#4F77AA',
        borderRadius: '4px'
      },
      shareButtonStyle: {
        // borderColor: 'rgba(47, 125, 205, 1)',
        // backgroundColor: 'rgba(47, 125, 205, 1)',
        // borderRadius: '4px',
        // display: 'block',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        // height: '44px',
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.5)'
      },
      shareBtn: {
        display: 'block'
      }
    }
  },

  computed: {
    // 是否企业微信
    isWxWok() {
      return (window.navigator.userAgent.includes('wxwork') || window.navigator.userAgent.includes('webdebugger'))
    }
  },

  methods: {
    openCallPhone(e, val) {
      callPhone(val)
    },

    // 打开分享弹窗
    switchShareOverlay() {
      postMessageSendInfo('OPENSHARE', 'open')
    },

    // 接收信息并发送名片模板高度信息给父页面更新 iframe 高度 及其它信息
    getInfoSendHeight() {
      const that = this
      postMessageReceiveInfo(
        (keyword, data) => {
          if (keyword === 'USERINFO' && data) {
            this.data = data
            // this.isShow = true;
          }
        },
        // 返回名片页面高度
        {
          keyword: 'IFRAMEREADY',
          data: {
            // height: document.getElementsByClassName('over-wrapper')[0].scrollHeight,
            height: document.getElementsByClassName('bigBox')[0].scrollHeight,
            uploadButtonStyle: that.uploadButtonStyle,
            shareButtonStyle: that.shareButtonStyle,
            shareBtn: that.shareBtn,
            templateName: that.name
          }
        }
      )

      window.addEventListener('popstate', () => {
        console.log('popstate')
        wx.closeWindow()
      }, false)

      window.addEventListener('touchstart', (e) => {
        const { clientX, clientY, screenX, screenY } = e.changedTouches[0]
        postMessageSendInfo('TOUCH', {
          templateName: this.name,
          type: 'touchstart',
          touch: { clientX, clientY, screenX, screenY }
        })
      })

      window.addEventListener('touchmove', (e) => {
        const { clientX, clientY, screenX, screenY } = e.changedTouches[0]
        postMessageSendInfo('TOUCH', {
          templateName: this.name,
          type: 'touchmove',
          touch: { clientX, clientY, screenX, screenY }
        })
      })

      window.addEventListener('touchend', (e) => {
        const { clientX, clientY, screenX, screenY } = e.changedTouches[0]
        postMessageSendInfo('TOUCH', {
          templateName: this.name,
          type: 'touchend',
          touch: { clientX, clientY, screenX, screenY }
        })
      })
    }
  }
}
